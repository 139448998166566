@use '@angular/material' as mat;
@use "sass:map";
@include mat.elevation-classes();
@include mat.app-background();


$my-app-primary: mat.m2-define-palette(mat.$m2-gray-palette, 600);
$my-app-accent: mat.m2-define-palette(mat.$m2-deep-purple-palette, 500, 900, A100);
$my-app-warn: mat.m2-define-palette(mat.$m2-red-palette);
$my-app-theme: mat.m2-define-light-theme((
    color: (
      primary: $my-app-primary,
      accent: $my-app-accent,
      warn: $my-app-warn,
    ),
    typography: mat.m2-define-typography-config(),
    density: 0,
));

$my-app-bg: map.get(mat.$m2-gray-palette, 600);

@include mat.elevation-classes();
@include mat.app-background();
@include mat.button-theme($my-app-theme);

@include mat.datepicker-theme($my-app-theme);

@include mat.all-component-themes($my-app-theme);
